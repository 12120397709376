import React, { Component } from 'react';

let clientNames = [];

class ClientsDropdown extends Component {
  constructor(props) {
    super(props);

    const res = JSON.parse(sessionStorage.getItem('USER_DATA'));

    if (res) {
      if (res.ClientNames && res.ClientNames.length > 0) clientNames.push(...res.ClientNames);

      if (res['Team Type'] === 'Broker') {
        clientNames.push('New Client');
      }
    }
  }
  componentWillUnmount = () => {
    clientNames = [];
  };

  render() {
    return (
      <div>
        <select
          value={this.props.value}
          onChange={this.props.change}
          className="selectdiv"
          data-testid="select-option"
        >
          <option>{''}</option>
          {clientNames.map((e, key) => {
            return (
              <option data-testid="option-dropdown" key={key} value={e}>
                {e}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
}

export default ClientsDropdown;
